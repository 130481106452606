<template>
  <div class="w-100 transition">
    <GenericReport
      title="Relatório de Recebimento de Materais OPME"
      :route="route"
      :topFilters="topFilters"
      :sideFilters="sideFilters"
      :fields="fields"
      tipoRelatorio="relatorioMateriaisOPME"
    />
  </div>
</template>

<script>
import moment from 'moment';

import GenericReport from '@/components/Table/GenericReport';

export default {
  name: 'relatorioEntradasOpme',
  components: {
    GenericReport,
  },
  data() {
    return {
      route: 'report/entradas',
      topFilters: {
        id_unidade: {
          type: 'genericSelect',
          route: 'unidade',
          firstOption: {
            value: null,
            label: 'Unidade Destino',
          },
        },
        id_tipo_material: {
          type: 'select',
          firstOption: {
            value: '3,4',
            label: 'OPME - Todos',
          },
          defaultValue: '3,4',
          // TODO Hardcoded tipo material
          options: [
            { value: 3, text: 'OPME Comodato' },
            { value: 4, text: 'OPME Consignado' },
          ],
        },
        date: {
          type: 'rangeDate',
          defaultInitDate: moment().subtract(7, 'days'),
          defaultEndDate: moment(),
        },
      },
      sideFilters: {
        id: {
          type: 'input',
          label: 'ID',
        },
        descricao: {
          type: 'input',
          label: 'Descrição',
        },
      },
      fields: [
        {
          label: 'Tipo de material',
          key: 'tipoMaterial',
          formatter: (value) => value && value.nome,
        },
        {
          label: 'ID',
          key: 'shownId',
        },
        {
          label: 'Descrição',
          key: 'descricao',
        },
        {
          label: 'Fornecedor',
          key: 'fornecedor',
          formatter: (value) => value && value.nome,
        },
        {
          label: 'Unidade Destino',
          key: 'unidadePatrimonio',
          formatter: (value) => value && value.nome,
        },
        {
          label: 'Data/Hora',
          key: 'createdAt',
          formatter: (value) => moment(value).format('DD/MM/YY HH:mm'),
        },
        {
          label: 'Data do procedimento',
          key: 'data_procedimento',
          formatter: (value) => (value ? moment(value).format('DD/MM/YY') : '-'),
        },
        {
          label: 'Horário do Procedimento',
          key: 'horario_procedimento',
        },
        {
          label: 'Transferência Arsenal',
          key: 'transferenciaArsenal',
          formatter: (value) => (value ? moment(value).format('DD/MM/YY HH:mm') : '-'),
        },
        {
          label: 'Saida Setor',
          key: 'saidaSetor',
          formatter: (value) => (value ? moment(value).format('DD/MM/YY HH:mm') : '-'),
        },
        {
          label: 'Usuário',
          key: 'usuario',
          formatter: (value) => value && value.nome,
        },
        {
          label: 'Aviso',
          key: 'aviso',
        },
        {
          label: 'Devolução',
          key: 'devolucao',
          formatter: (value) => (value ? moment(value).format('DD/MM/YY HH:mm') : '-'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition {
  transition: 0.3s ease-in-out;
}

#report h2 {
  margin-bottom: 30px;
}

#report table {
  background-color: #FFF;
}

#report table thead th {
  color: #7E829F;
  padding-bottom: 50px;
  font-weight: 900;
}

</style>
